import Swal from "sweetalert2";

const setSecondryAlert = (iconType, message) => {
  const Toast = Swal.mixin({
    toast: true,
    position: "top",
    showConfirmButton: false,
    timer: 4000, 
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });
  
  Toast.fire({
    icon: iconType,
    title: message,
  });
};

export default setSecondryAlert;
