import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import logo from "../resources/images/logo.webp";
import useStore from "../state/store";
import Loader from "../components/loader/index";
import NavBar from "../components/navbar";
import LandingPageFooter from "../components/footer/landing-page";

const SecondryPublicLayout = () => {
  const user = useStore((state) => state.user);
  const navigate = useNavigate();

  function goBack() {
    if (user) {
      navigate("/private/add-review");
    } else {
      navigate("/");
    }
  }

  const isLoading = useStore((state) => state.isLoading);
  return (
    <div>
      {user? null  : (<NavBar />)}
      <div className="mx-auto px-4 sm:px-12 xl:max-w-6xl xl:px-0">
        {isLoading === true && <Loader route={"public"} />}
        <div className="py-10 max-[370px]:p-6 max-[370px]:mx-2 ">
          <div className="flex items-center ">
            <div className="sm:w-[100px] z-50  w-[28px] ">
            {user ? (
                <a
                  onClick={() => goBack()}
                  className="cursor-pointer flex gap-2 max-sm:bg-transparent rounded max-sm:p-1 items-center text-[#005655] underline underline-offset-4 hover:text-primary group/backButton"
                >
                  <span>
                    <svg
                      width="20px"
                      height="20px"
                      viewBox="0 0 1024 1024"
                      xmlns="http://www.w3.org/2000/svg"
                      className="group-hover/backButton:fill-primary max-sm:fill-[#005655] max-sm:w-[25px] max-sm:h-[25px]"
                      fill="#005655"
                    >
                      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        <path d="M224 480h640a32 32 0 1 1 0 64H224a32 32 0 0 1 0-64z"></path>
                        <path d="m237.248 512 265.408 265.344a32 32 0 0 1-45.312 45.312l-288-288a32 32 0 0 1 0-45.312l288-288a32 32 0 1 1 45.312 45.312L237.248 512z"></path>
                      </g>
                    </svg>
                  </span>
                  <span className="sm:block hidden">Go Back</span>
                </a>
              ) : null}
            </div>
            <div className="w-full sm:-ml-[100px] -ml-[28px] flex justify-center">
              <img
                className="w-[250px] max-sm:w-[150px] h-auto"
                src={logo}
                alt=""
              ></img>
            </div>
          </div>
          <Outlet />
        </div>
      </div>
      <LandingPageFooter />
    </div>
  );
};

export default SecondryPublicLayout;
