import React from "react";
import axios from "axios";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { z } from "zod";

import { Button } from "../../../components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../components/ui/form";
import { Input } from "../../../components/ui/input";
import setAlert from "../../../components/alert/alert";
import useStore from "../../../state/store";

const formSchema = z.object({
  recovery_token: z.string().min(1, {
    message: "This field is required.",
  }),
  
});

const AddRecoveryCodes = () => {
  document.title = "KudosTrack - Add recovery token";
  const location = useLocation();
  let email = location.state.userDetails.email 
  useEffect(() => {
    console.log(location);
    if (
      location.state.userDetails == null
    ) {
      navigate("/forgot-password");
    }
  }, []);

  const navigate = useNavigate();
  const setIsLoading = useStore((state) => state.setIsLoading);

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      recovery_token: "",
    },
  });

  const onSubmit = (values) => {
    setIsLoading(true);
    const FormData = require("form-data");
    let data = new FormData();
    data.append("recovery_token", values.recovery_token);

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BACKEND_URL}/submit-recovery-token`,
      data: data,
    };
    axios
      .request(config)
      .then((response) => {
        console.log(response);
        let recoveryToken = response.data.data.recovery_token;
        navigate("/new-password" , { state: { recoveryToken , email} });
        setIsLoading(null);
      })
      .catch((error) => {
        setAlert("error", error.response.data.message);
        setIsLoading(null);
      });
  };

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="space-y-4 w-[320px] max-[480px]:w-[unset]"
      >
        <FormField
          control={form.control}
          name="recovery_token"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Recovery Code</FormLabel>
              <FormControl>
                <Input
                  name="recovery_token"
                  type="text"
                  placeholder="Recovery Code"
                  {...field}
                />
              </FormControl>
              <FormMessage className="text-[12px]" />
            </FormItem>
          )}
        />
        <Button
          className="w-full bg-[#007A77] hover:bg-[#005655]"
          type="submit"
        >
          Submit
        </Button>
        <div className="text-center text-sm w-full max-[480px]:w-[238px]">
          <span>
            Do you have an account? <Link className="text-[#007A77] font-medium hover:text-[#005655]" to="/"> Sign Up</Link>
          </span>
        </div>
      </form>
    </Form>
  );
};

export default AddRecoveryCodes;
