import React, { useEffect } from "react";
import video from "../../resources/videos/sample.mp4";

export const LandingPage = () => {
  useEffect(() => {
    document.title = "KudosTrack - Home";
  }, []);
  return (
    <section className="pb-20 sm:pt-24 md:pt-24">
      <div className="mx-auto px-4 sm:px-12 xl:max-w-6xl xl:px-0">
        <div className="relative">
          <h1 className="text-center text-4xl font-bold text-white dark:text-white sm:text-6xl lg:text-left lg:text-7xl max-sm:mt-[20px]">
            Welcome to KudosTrack
          </h1>

          <div className="relative items-center gap-12 lg:flex">
            <div className="text-center sm:mx-auto sm:w-11/12 md:mt-12 md:w-4/5 lg:mt-0 lg:mr-auto lg:w-6/12 lg:text-left">
              <p className="mt-12 text-lg text-gray-300 dark:text-gray-300 sm:text-xl">
                KudosTrack is our innovative platform designed to foster a
                transparent and constructive environment where colleagues can
                provide and receive review. Here’s how it works:
                <br />
                <br />
                <strong>Add Reviews:</strong> Easily provide review for your
                colleagues to help them improve and grow.
                <br />
                <strong>Receive Reviews:</strong> Your colleagues can also share
                their insights about your performance.
                <br />
                <strong>Admin Control:</strong> With permissions managed by the
                admin panel, you can access reviews only when granted the
                necessary permissions.
                <br />
                <strong>Secure and Private:</strong> All review is handled
                securely to ensure privacy and confidentiality.
                <br />
                <br />
                Join KudosTrack in promoting a culture of continuous improvement
                and mutual support.
              </p>
              <a
                href="/sign-in"
                className="add-sign-in-button relative my-8 flex h-11 w-max items-center justify-center px-6 before:absolute before:inset-0 before:rounded-full before:bg-white before:transition-transform before:duration-300 active:duration-75 active:before:scale-95 dark:before:bg-primaryLight max-lg:mx-auto lg:mx-0"
              >
                <span className="relative text-base font-semibold text-[#007A77] dark:text-gray-900">
                  Get started
                </span>
              </a>
            </div>
            <div className="mt-12 w-full overflow-hidden sm:mt-20 lg:-mt-8 lg:w-6/12">
              <video
                className="w-full"
                src={video}
                type="video/mp4"
                autoPlay
                muted
                loop
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LandingPage;
