import React from "react";
import { FadeLoader } from "react-spinners";

const Loader = ({ route }) => {
  return (
    <div
      className={`z-50 w-screen bg-white ${
        route === "private" ? "bg-opacity-75" : ""
      } w-full h-full fixed top-0 left-0 flex justify-center items-center`}
    >
      <FadeLoader color="#007A77" height={12} radius={2} margin={0} width={4} />
    </div>
  );
};

export default Loader;
