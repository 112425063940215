import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import useStore from "../../../state/store";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import packageJson from '../../../../package.json'

import { Button } from "../../../components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../components/ui/form";
import { Input } from "../../../components/ui/input";
import setAlert from "../../../components/alert/alert";
import authHandler from "../../../router/auth-handler/index";
import ToggleEye from "../../../components/toggle-eye";

const formSchema = z.object({
  email: z.string().email("This is not a valid email."),
  email: z.string().min(1, {
    message: "This field is required.",
  }),
  password: z.string().min(1, {
    message: "This field is required.",
  }),
});

export const Login = () => {
  document.title = "KudosTrack - SignIn";
  
  const addUser = useStore((state) => state.addUser);
  const navigate = useNavigate();
  const setIsLoading = useStore((state) => state.setIsLoading);
  const setQualities = useStore((state) => state.setQualities);
  const setEmployees = useStore((state) => state.setEmployees);
  const [type, setType] = useState("password");
  const [isPasswordTyped, setIsPasswordTyped] = useState(false);

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const onSubmit = async (values) => {
    setIsLoading(true);
    try {
      const accessTokenResponse = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/user-login`,
        {
          email: values.email,
          password: values.password,
        }
      );
      storeToken(accessTokenResponse);
    } catch (err) {
      setAlert("error", err.response.data.message);
      setIsLoading(null);
    }
  };

  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => getAccessToken(tokenResponse.access_token),
  });

  const getAccessToken = async (token) => {
    setIsLoading(true);
    try {
      const accessTokenResponse = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/google-login`,
        {
          data: token,
        }
      );
      storeToken(accessTokenResponse);
    } catch (err) {
      setAlert("error", err.response.data.message);
      setIsLoading(null);
    }
  };

  const storeToken = (accessTokenResponse) => {
    if (accessTokenResponse.data.status == "success") {
      setIsLoading(true);
      axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/user/me`, {
          headers: {
            Authorization: `Bearer ${accessTokenResponse.data.data}`,
          },
        })
        .then((response) => {
          addUser({
            access_token: accessTokenResponse.data.data,
            email: response.data.data.email,
            name: response.data.data.name,
            userId: response.data.data.id,
          });
          setQualityList(accessTokenResponse.data.data);
          getEmployeeList(accessTokenResponse.data.data);
        })
        .catch((error) => {
          authHandler(error, navigate);
          setIsLoading(null);
        });
    }
    const setQualityList = (token) => {
      setIsLoading(true);
      axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/user/get-quality-list`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setQualities(response.data.data);
          setIsLoading(null);
        })
        .catch((error) => {
          authHandler(error, navigate);
          setIsLoading(null);
        });
    };

    const getEmployeeList = (token) => {
      setIsLoading(true);
      axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/user/get-all-users`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setEmployees(response.data.data);
          navigate("/private/add-review");
          setIsLoading(null);
        })
        .catch((error) => {
          authHandler(error, navigate);
          setIsLoading(null);
        });
    };
  };

  const handleToggle = () => {
    if (type === "password") {
      setType("text");
    } else {
      setType("password");
    }
  };

  const handlePasswordChange = (event) => {
    setIsPasswordTyped(event.target.value.length > 0);
  };

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="space-y-4 w-[320px] max-[480px]:w-[unset]"
      >
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Email</FormLabel>
              <FormControl>
                <Input
                  name="email"
                  type="email"
                  placeholder="Email"
                  {...field}
                />
              </FormControl>
              <FormMessage className="text-[12px]" />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="password"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Password</FormLabel>
              <FormControl>
                <div className="border rounded-md relative flex gap-2">
                  <Input
                    className="!border-0 !focus:outline-none"
                    name="password"
                    type={type}
                    placeholder="Password"
                    {...field}
                    onChange={(event) => {
                      field.onChange(event);
                      handlePasswordChange(event);
                    }}
                  />
                  {isPasswordTyped && (
                    <div
                      className="flex item-center w-[15px] h-[15px]"
                      onClick={handleToggle}
                    >
                      <ToggleEye type={type} />
                    </div>
                  )}
                </div>
              </FormControl>
              <FormMessage className="text-[12px]" />
            </FormItem>
          )}
        />
        <div className="w-full flex justify-end pr-1 !m-0 pt-1">
          <Link
            to={"/forgot-password"}
            className="text-[#007A77] font-medium text-[11px] hover:text-[#005655]"
          >
            forgot your password?
          </Link>
        </div>
        <Button
          className="w-full bg-[#007A77] hover:bg-[#005655]"
          type="submit"
        >
          LOG IN
        </Button>

        <div className="w-full flex justify-center gap-3 items-center">
          <div className="w-10 h-[1px] bg-gray-600"></div>
          <p className="text-gray-600 font-medium text-[11px]">Or</p>
          <div className="w-10 h-[1px] bg-gray-600"></div>
        </div>

        <Button
          onClick={() => login()}
          className="w-full bg-white border border-gray-400 flex items-center gap-2 text-gray-700 font-normal hover:text-gray-800 hover:bg-white hover:border-2 hover:border-gray-800"
          type="button"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="30"
            height="30"
            viewBox="0 0 48 48"
          >
            <path
              fill="#fbc02d"
              d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12	s5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24s8.955,20,20,20	s20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
            ></path>
            <path
              fill="#e53935"
              d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039	l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
            ></path>
            <path
              fill="#4caf50"
              d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36	c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
            ></path>
            <path
              fill="#1565c0"
              d="M43.611,20.083L43.595,20L42,20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571	c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
            ></path>
          </svg>
          Continue with Google
        </Button>
        <div className="flex gap-2 pt-3 justify-center">
          <div className="flex">
            <Link
              to={"/about-us"}
              className="text-gray-400 font-medium text-[11px] hover:text-[#005655] underline"
            >
              About Us
            </Link>
          </div>
          <div className="flex">
            <Link
              to={"/privacy-policy"}
              className="text-gray-400 font-medium text-[11px] hover:text-[#005655] underline"
            >
              Privacy Policy
            </Link>
          </div>
          <div className="flex">
            <Link
              to={"/user-guide"}
              className="text-gray-400 font-medium text-[11px] hover:text-[#005655] underline"
            >
              User Guide
            </Link>
          </div>
        </div>    
        <p className="text-center text-xs text-gray-400">
          {packageJson.version && 'v' + packageJson.version}
        </p>
      </form>
    </Form>
  );
};
