import React from "react";
import { Outlet } from "react-router-dom";
import logo from "../resources/images/logo.webp";
import useStore from "../state/store";
import Loader from "../components/loader/index";

const PublicLayout = () => {
  const isLoading = useStore((state) => state.isLoading);
  return (
    <div>
      <div className="w-screen h-screen flex items-center justify-center">
        {isLoading === true && <Loader route={"public"} />}
        <div className="p-10 shadow-[rgba(50,50,93,0.25)_0px_2px_5px_2px] rounded-lg max-[370px]:p-6 max-[370px]:mx-2">
          <div className="w-full flex justify-center mb-6">
          <a href="/">
            <img className="w-[150px] h-auto" src={logo} alt=""/>
            </a>
          </div>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default PublicLayout;
