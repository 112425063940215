import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";

import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/ui/table";

import { Label } from "../../../components/ui/label";

import { ChevronDown, ChevronUp, Check, ChevronsUpDown } from "lucide-react";

import { cn } from "../../../lib/utils";
import { Button } from "../../../components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "../../../components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../components/ui/popover";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../../components/ui/accordion";
import {
  Card,
  CardContent,
  CardHeader,
} from "../../../components/ui/card";
import useStore from "../../../state/store";
import authHandler from "../../../router/auth-handler/index";
import ReviewLoader from "../../../components/review-loader/index";

const ContributorReview = () => {
  document.title = "KudosTrack - Member Review";
  const qualityTypes = [
    { id: -1, name: "All" },
    { id: 1, name: "Positive" },
    { id: 0, name: "Negative" },
  ];

  const stateQualities = useStore((state) => state.qualities);
  const defaultQualities = [{ id: -1, name: "All" }, ...stateQualities];

  const navigate = useNavigate();
  const user = useStore((state) => state.user);
  const setIsReviewLoading = useStore((state) => state.setIsReviewLoading);
  const { contributorId } = useParams();
  const [open, setOpen] = React.useState(false);
  const [reviews, setReviews] = React.useState([]);
  const [qualities, setQualities] = React.useState(defaultQualities);
  const [qualityFilterValue, setQualityFilterValue] = React.useState(
    defaultQualities[0]
  );
  const [qualityTypeFilterValue, setQualityTypeFilterValue] = React.useState(
    qualityTypes[0]
  );
  const isReviewLoading = useStore((state) => state.isReviewLoading);

  useEffect(() => {
    if(user != null){
      setIsReviewLoading(true)
      fetchContributorReviews(defaultQualities[0].id, qualities[0].id);
    }
  }, []);

  const fetchContributorReviews = (qualityId, qualityTypeId) => {
    setIsReviewLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/user/get-contributor-reviews`,
        {
          contributor_id: contributorId,
          quality_id: qualityId,
          quality_type: qualityTypeId,
        },
        {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
          },
        }
      )
      .then((response) => {
        setReviews(response.data.data);
        setIsReviewLoading(null);
      })
      .catch((error) => {
        authHandler(error, navigate);
        setIsReviewLoading(null);
      });
  };

  const Select = ({ className, onValueChange, value, children }) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleSelectClick = () => {
      setIsOpen(!isOpen);
    };

    const handleOptionClick = (optionValue) => {
      onValueChange(optionValue);
      setIsOpen(false);
    };

    return (
      <div className={`relative inline-block w-full ${className}`}>
        <div
          className="block w-full bg-white border border-black hover:border-gray-500 px-4 py-2 pr-4 rounded shadow leading-tight focus:outline-none focus:shadow-outline cursor-pointer"
          onClick={handleSelectClick}
        >
          <div className="flex justify-between items-center">
            <span>{value ? value.name : "Select Type"}</span>
            <div className="pointer-events-none text-gray-700">
              {isOpen ? (
                <ChevronUp className="h-4 w-4" />
              ) : (
                <ChevronDown className="h-4 w-4" />
              )}
            </div>
          </div>
        </div>
        {isOpen && (
          <div className="absolute mt-1 w-full rounded-md bg-white shadow-lg z-10 border border-gray-200">
            <ul className="max-h-60 rounded-md py-1 text-base leading-6 shadow-xs overflow-auto focus:outline-none sm:text-base sm:leading-5">
              {React.Children.map(children, (child) =>
                React.cloneElement(child, {
                  onClick: () => handleOptionClick(child.props.value),
                  selected: child.props.value.id === value.id,
                })
              )}
            </ul>
          </div>
        )}
      </div>
    );
  };

  const SelectItem = ({ children, className, onClick, selected }) => {
    return (
      <li
        onClick={onClick}
        className={`cursor-pointer select-none relative py-2 pl-3 pr-9 ${className}`}
      >
        <Check
          className={`h-4 min-w-[24px] absolute left-2 top-3 ${
            selected ? "opacity-100" : "opacity-0"
          }`}
        />
        {children}
      </li>
    );
  };

  return (
    <div className="mt-[66px]">
      {isReviewLoading === true && <ReviewLoader/>}
      <Card className="h-[700px] w-[1024px] rounded overflow-y-auto">
        <CardHeader className="bg-white z-40">
          <div className="mx-auto w-full flex justify-end gap-8 py-2">
            <div className="flex flex-col gap-2 w-[150px]">
              <Label htmlFor="">Type</Label>
              <Select
                className="font-medium"
                onValueChange={(val) => {
                  const selectedType = qualityTypes.find(
                    (quality) => quality.id == val.id
                  );
                  setQualityTypeFilterValue(selectedType);
                  fetchContributorReviews(qualityFilterValue.id, val.id);
                }}
                value={qualityTypeFilterValue}
              >
                {qualityTypes.map((type) => (
                  <SelectItem
                    key={type.id}
                    className={`rounded m-1 border-black border font-normal pl-[40px] bg-${
                      type.name.toLowerCase() === "positive"
                        ? "green-400"
                        : type.name.toLowerCase() === "negative"
                        ? "red-400"
                        : "white"
                    } hover:bg-${
                      type.name.toLowerCase() === "positive"
                        ? "green-500"
                        : type.name.toLowerCase() === "negative"
                        ? "red-500"
                        : "gray-200"
                    }`}
                    value={type}
                  >
                    {type.name}
                  </SelectItem>
                ))}
              </Select>
            </div>
            <div className="flex flex-col gap-2">
              <Label htmlFor="">Quality</Label>
              <Popover open={open} onOpenChange={setOpen}>
                <PopoverTrigger asChild>
                  <Button
                    variant="outline"
                    role="combobox"
                    aria-expanded={open}
                    className="w-[200px] max-sm:w-[288px] justify-between bg-transparent hover:bg-transparent border-black"
                  >
                    {qualityFilterValue
                      ? qualityFilterValue?.name
                      : "Select Quality"}
                    <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="w-[200px] h-[250px] pb-2 ">
                  <Command>
                    <CommandInput placeholder="Search Quality..." />
                    <CommandEmpty>No Quality found.</CommandEmpty>
                    <CommandGroup className="overflow-y-scroll">
                      {qualities.map((quality) => (
                        <CommandItem
                          key={quality.id}
                          value={quality.name}
                          onSelect={(currentValue) => {
                            const newQualityFilterValue =
                              currentValue === quality.name.toLowerCase()
                                ? quality
                                : "";
                            setQualityFilterValue(newQualityFilterValue);
                            setOpen(false);
                            fetchContributorReviews(
                              newQualityFilterValue.id,
                              qualityTypeFilterValue.id
                            );
                          }}
                        >
                          <Check
                            className={cn(
                              "mr-2 h-4 min-w-[24px]",
                              qualityFilterValue != null
                                ? qualityFilterValue.name === quality.name
                                  ? "opacity-100"
                                  : "opacity-0"
                                : "opacity-0"
                            )}
                          />
                          {quality.name}
                        </CommandItem>
                      ))}
                    </CommandGroup>
                  </Command>
                </PopoverContent>
              </Popover>
            </div>
          </div>
        </CardHeader>
        <CardContent className="">
          <div className="mx-auto w-full pt-[20px]">
            <div className="">
              <Table>
                <TableCaption></TableCaption>
                <TableHeader className="border-b-1 bg-white border-black w-[1024px] border-b-[1px] z-40">
                  <TableRow className="hover:bg-transparent text-[17px]">
                    <TableHead className="w-[150px] text-black">Date</TableHead>
                    <TableHead className="w-[200px] text-black">
                      Reported By
                    </TableHead>
                    <TableHead className="w-[150px] text-black">
                      Quality
                    </TableHead>
                    <TableHead className="w-[150px] text-black">Type</TableHead>
                    <TableHead className="text-black">
                      Description of Incident
                    </TableHead>
                  </TableRow>
                </TableHeader>
                <div className=" mt-[50px]"></div>
                <TableBody >
                  {reviews.map((review) => {
                    return (
                      <TableRow className="cursor-pointer" key={review.id}>
                        <TableCell className="font-medium w-[150px]">
                          {review.date}
                        </TableCell>
                        <TableCell className="max-w-[200px] overflow-hidden">
                          <span className="">{review.reviewer}</span>
                        </TableCell>
                        <TableCell className=" w-[150px]">
                          {review.quality}
                        </TableCell>
                        <TableCell
                          className={`
                        !w-[150px] 
                        ${
                          review.quality_type == "Positive"
                            ? "text-green-700"
                            : "text-red-700"
                        }`}
                        >
                          {review.quality_type}
                        </TableCell>
                        <TableCell className="w-[374px]">
                          <Accordion type="single" collapsible>
                            <AccordionItem value="item-1">
                              <AccordionTrigger>
                                <span className="max-w-[320px] overflow-hidden">{review.content.substring(0, 30)}...</span>
                              </AccordionTrigger>
                              <AccordionContent>
                                <p className="max-w-[342px] break-words">
                                  {review.content}</p>
                              </AccordionContent>
                            </AccordionItem>
                          </Accordion>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default ContributorReview;
