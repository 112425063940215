import setAlert from "../../components/alert/alert";

const authHandler = (error, navigate) => {
  if (error.response.status === 401) {
    localStorage.removeItem("my-app-user");
    navigate("/");
  } else {
    setAlert("error", error.response.data.message);
  }
};

export default authHandler;