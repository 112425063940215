import React from "react";

const Footer = ({ props }) => {
  const currentYear = new Date().getFullYear();

  return (
    <div className="bottom-0 w-full flex max-md:flex-col justify-center max-md:items-center bg-transparent lg:gap-28">
      <div className={`flex py-4 text-base ${props === "/private/change-password" ? "md:text-black" : "md:text-white"} opacity-80 max-lg:hidden`}>
        <p>
          &copy; {currentYear}{" "}
          <a
            className="text-"
            href="https://sanmarksolutions.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <strong>Sanmark Solutions PVT Ltd.</strong>
          </a>{" "}
          All rights reserved.
        </p>{" "}
      </div>
      <div className="max-md:flex-col justify-center">
        <div className={`flex py-4 text-base ${props === "/private/change-password" ? "md:text-black" : "md:text-white"} opacity-80 gap-4 max-[475px]:gap-2 justify-center`}>
          <a href="/about-us">About Us</a> {"|"}
          <a href="/privacy-policy">Privacy Policy</a> {"|"}
          <a href="/user-guide">User Guide</a>
        </div>
        <div className="justify-center">
          <p className="lg:hidden text-base md:text-white opacity-80 text-center max-sm:px-2">
            &copy; {currentYear}{" "}
            <a
              href="https://sanmarksolutions.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <strong>Sanmark Solutions PVT Ltd.</strong>
            </a>{" "}
            All rights reserved.
          </p>{" "}
        </div>
      </div>
    </div>
  );
};

export default Footer;
