import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import useStore from "../../../state/store";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { Button } from "../../../components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../components/ui/form";
import { Input } from "../../../components/ui/input";
import setAlert from "../../../components/alert/alert";
import authHandler from "../../../router/auth-handler/index";
import logo from "../../../resources/images/logo.webp";
import ToggleEye from "../../../components/toggle-eye";

const formSchema = z
  .object({
    current: z.string().min(1, {
      message: "Password required.",
    }),
    new: z
      .string()
      .min(1, {
        message: "Password required.",
      })
      .min(8, {
        message: "Minimum 8 Charactors required.",
      })
      .regex(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#\-+])[A-Za-z\d@$!%*?&#\-+]+$/,
        {
          message: `* One uppercase letter<br/>* One lowercase letter<br/>* One number<br/>* One special character.`,
        }
      ),
    confirm: z.string().min(1, {
      message: "Password required.",
    }),
  })
  .refine((data) => data.new === data.confirm, {
    message: "Passwords don't match.",
    path: ["confirm"],
  });

const ChangePassword = () => {
  document.title = "KudosTrack - Change Password";
  const user = useStore((state) => state.user);
  const [typeNewpassword, setTypeNewpassword] = useState("password");
  const [typeConfirmpassword, setTypeConfirmpassword] = useState("password");
  const [typeCurrentpassword, setTypeCurrentpassword] = useState("password");
  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      current: "",
      new: "",
      confirm: "",
    },
  });
  const navigate = useNavigate();
  const setIsLoading = useStore((state) => state.setIsLoading);
  const addUser = useStore((state) => state.addUser);

  const onSubmit = async (values) => {
    setIsLoading(true);
    const FormData = require("form-data");
    let data = new FormData();
    data.append("newPassword", values.new);
    data.append("newPassword_confirmation", values.confirm);
    data.append("currentPassword", values.current);

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BACKEND_URL}/user/change-password`,
      headers: {
        Authorization: `Bearer ${user?.access_token}`,
      },
      data: data,
    };
    delete config.headers["Content-Type"];
    axios
      .request(config)
      .then((response) => {
        if (response.data.status === "success") {
          if (response.data.data === null) {
            setAlert("success", response.data.message);
          } else {
            setAlert("success", response.data.message);
            localStorage.removeItem("my-app-user");
          }
        } else if (response.data.status === "fail") {
          setAlert("error", response.data.message);
        } else if (response.data.status === "error") {
          setAlert("error", response.message);
        }
        setIsLoading(null);
        form.reset();
      })
      .catch((error) => {
        console.log(error.response.data.message);
        setAlert("error", error.response.data.message);
        authHandler(error, navigate);
        setIsLoading(null);
      });
  };

  const handleToggle = (passwordType) => {
    if (passwordType === "current") {
      typeCurrentpassword === "password"
        ? setTypeCurrentpassword("text")
        : setTypeCurrentpassword("password");
    } else if (passwordType === "new") {
      typeNewpassword === "password"
        ? setTypeNewpassword("text")
        : setTypeNewpassword("password");
    } else if (passwordType === "confirm") {
      typeConfirmpassword === "password"
        ? setTypeConfirmpassword("text")
        : setTypeConfirmpassword("password");
    }
  };
  return (
    <div className="w-screen h-screen flex items-center justify-center">
      <div className="p-10 shadow-[rgba(50,50,93,0.25)_0px_2px_5px_2px] rounded-lg max-[370px]:p-6 max-[370px]:mx-2">
        <div className="w-full flex justify-center mb-6">
          <img className="w-[150px] h-auto" src={logo} alt=""></img>
        </div>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="space-y-4 w-[320px] max-[480px]:w-[unset]"
          >
            <FormField
              control={form.control}
              name="current"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Current Password</FormLabel>
                  <FormControl>
                    <div className="border rounded-md relative flex gap-2">
                      <Input
                        className="!border-0 !focus:outline-none"
                        name="current"
                        type={typeCurrentpassword}
                        placeholder="Current Password"
                        Confirm
                        {...field}
                      />
                      <div
                        className="flex item-center w-[15px] h-[15px]"
                        onClick={() => handleToggle("current")}
                      >
                        <ToggleEye type={typeCurrentpassword} />
                      </div>
                    </div>
                  </FormControl>
                  <FormMessage className="text-[12px]" />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="new"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>New Password</FormLabel>
                  <FormControl>
                    <div className="border rounded-md relative flex gap-2">
                      <Input
                        className="!border-0 !focus:outline-none"
                        name="new"
                        type={typeNewpassword}
                        placeholder="New Password"
                        {...field}
                      />
                      <div
                        className="flex item-center w-[15px] h-[15px]"
                        onClick={() => handleToggle("new")}
                      >
                        <ToggleEye type={typeNewpassword} />
                      </div>
                    </div>
                  </FormControl>
                  <FormMessage className="text-[12px]" />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="confirm"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Confirm Password</FormLabel>
                  <FormControl>
                    <div className="border rounded-md relative flex gap-2">
                      <Input
                        className="!border-0 !focus:outline-none"
                        name="confirm"
                        type={typeConfirmpassword}
                        placeholder="Confirm  Password"
                        {...field}
                      />
                      <div
                        className="flex item-center w-[15px] h-[15px]"
                        onClick={() => handleToggle("confirm")}
                      >
                        <ToggleEye type={typeConfirmpassword} />
                      </div>
                    </div>
                  </FormControl>
                  <FormMessage className="text-[12px]" />
                </FormItem>
              )}
            />
            <Button
              className="w-full bg-[#007A77] hover:bg-[#005655]"
              type="submit"
            >
              Change password
            </Button>
          </form>
        </Form>
      </div>
    </div>
  );
};

export default ChangePassword;
