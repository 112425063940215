import React, { useEffect } from "react";

export const UserGuide = () => {
  useEffect(() => {
    document.title = "KudosTrack: User guide";
    const metaTag = document.querySelector('meta[name="description"]');
  }, []);

  return (
    <div className="">
      <section className="hero">
        <div className="hero-content pt-10">
          <h1 className="font-bold text-2xl">Kudos Track - User Guide</h1>
        </div>
      </section>

      <section className="company pt-10">
        <div className="user-guide-content">
          <ul className="list-disc pl-5 pt-5">
            <li className="pt-2 text-justify">Login with Credentials</li>
            <li className="pt-2 text-justify">
              Alternatively, you can use the "Sign in with Google" button and
              log in using your Sanmark email.
            </li>
            <li className="text-red-600 pt-2 text-justify">
              After logging in to the system, you need to change your password
              using the "Change Password" option located in the navigation bar's
              dropdown menu.
            </li>
            <li className="pt-2 text-justify">
              Using the desktop app or web view, you can add feedback for other
              users, view reviews provided by other users, and see reviews of
              your contributors.
            </li>
          </ul>
        </div>
      </section>
    </div>
  );
};

export default UserGuide;
