import { create } from "zustand";
import { persist } from "zustand/middleware";

const useStore = create(
  persist(
    (set) => ({
      user: null,
      addUser: (user) => set((state) => ({ user })),
      isLoading: null,
      setIsLoading: (isLoading) => set((state) => ({ isLoading })),
      qualities: [],
      setQualities: (qualities) => set((state) => ({ qualities })),
      employees: [],
      setEmployees: (employees) => set((state) => ({ employees })),
      isReviewLoading: null,
      setIsReviewLoading: (isReviewLoading) => set((state) => ({ isReviewLoading })),
    }),
    {
      name: "my-app-user",
    }
  )
);

export default useStore;
