import useStore from "../../state/store";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  const navigate = useNavigate();
  const user = useStore((state) => state.user);
  useEffect(() => {
    if (user === null) {
      navigate("/");
    }
  }, []);
  return children;
};
export default ProtectedRoute;
