import React, { useEffect } from "react";

export const AboutUs = () => {
  useEffect(() => {
    document.title = "KudosTrack - About Us";
    const metaTag = document.querySelector('meta[name="description"]');
    if (metaTag) {
      metaTag.setAttribute(
        "content",
        "The Kudos Track incident reporting software helps you facilitate a sophisticated feedback mechanism within organizations, enhancing the way incidents are recorded, managed, and resolved."
      );
    }
  }, []);

  return (
    <div className="">
      <section className="hero">
        <div className="hero-content pt-10">
          <h1 className="font-bold text-2xl ">
            Welcome to Kudos Track – Empowering Recognition and Engagement
          </h1>
          <p className="pt-5 text-justify">
            At Kudos Track, we believe in the transformative power of
            recognition and positive feedback within the workplace. Our journey
            began with a simple yet profound vision: to create an environment
            where every contribution is acknowledged, and every achievement
            celebrated. In a world where hard work often goes unnoticed, we
            sought to build a platform that not only tracks accomplishments but
            also fosters a culture of appreciation and motivation.
          </p>
        </div>
      </section>

      <section className="company pt-10">
        <div className="company-content ">
          <h1 className="font-bold text-xl">
            Company Behind Kudos Track
          </h1>
          <p className="pt-5 text-justify">
            Behind the innovative Kudos Track platform stands Sanmark Solutions
            PVT LTD, a trailblazer in software development and digital
            solutions. With a rich history of creating cutting-edge technologies
            and platforms, Sanmark Solutions is dedicated to transforming the
            digital landscape through exceptional products and services. Our
            commitment to innovation, quality, and customer satisfaction is
            reflected in every project we undertake, from the Special Incident
            Recording System to Kudos Track. At Sanmark, we're not just
            developing software; we're crafting tools that make a difference in
            the way businesses operate and grow. Learn more about our journey
            and our suite of solutions at{" "}
            <a
              className="text-[#005655] underline"
              href="https://sanmarksolutions.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Sanmark Solutions
            </a>
            .
          </p>
        </div>
      </section>

      <section className="company pt-10">
        <div className="company-content ">
          <h1 className="font-bold text-xl">Our Mission</h1>
          <p className="pt-5 text-justify">
            Our mission is to revolutionize the way organizations engage with
            their employees by providing a comprehensive and seamless solution
            for recognizing and tracking achievements. Kudos Track is more than
            just a platform; it's a movement towards building healthier, more
            productive work environments where everyone feels valued and
            empowered.
          </p>
        </div>
      </section>

      <section className="company pt-10">
        <div className="company-content ">
          <h1 className="font-bold text-xl">Our Solution</h1>
          <p className="pt-5 text-justify">
            Drawing upon the expertise and insights gained from developing the
            Special Incident Recording System, we created Kudos Track to address
            the need for a secure, efficient, and user-friendly system dedicated
            to enhancing organizational morale and productivity. Our platform
            stands at the intersection of technology and human connection,
            leveraging advanced tools to facilitate meaningful interactions
            among team members.
          </p>
        </div>
      </section>

      <section className="company pt-10">
        <div className="company-content">
          <h1 className="font-bold text-xl">What Sets Us Apart</h1>
          <ul className="list-disc pl-5 pt-5">
            <li className="pt-2 text-justify">
              <b>User-Centric Design:</b> At the heart of Kudos Track is our
              commitment to user experience. Our platform is intuitively
              designed, making it easy for users to send and receive kudos,
              track achievements, and celebrate milestones.
            </li>
            <li className="pt-2 text-justify">
              <b>Data Security and Privacy:</b> We understand the importance of
              confidentiality and data security. Like our Special Incident
              Recording System, Kudos Track employs state-of-the-art encryption
              and secure practices to protect user information.
            </li>
            <li className="pt-2 text-justify">
              <b>Customizable Recognition Programs:</b> Recognizing that each
              organization is unique, we offer customizable features that allow
              companies to tailor their recognition programs to fit their
              specific culture and values.
            </li>
            <li className="pt-2 text-justify">
              <b>Analytics and Insights:</b> Kudos Track provides valuable
              insights into organizational health, employee engagement, and
              recognition trends, enabling leaders to make informed decisions
              and foster a positive work environment.
            </li>
          </ul>
        </div>
      </section>

      <section className="company pt-10">
        <div className="company-content ">
          <h1 className="font-bold text-xl">Our Commitment</h1>
          <p className="pt-5 text-justify">
            We are dedicated to continuous improvement and innovation, ensuring
            that Kudos Track evolves to meet the changing needs of the modern
            workplace. Our team of experts is constantly working on enhancements
            and new features that drive engagement and facilitate a culture of
            recognition
          </p>
        </div>
      </section>
      <section className="company pt-10">
        <div className="company-content ">
          <h1 className="font-bold text-xl">Join Us</h1>
          <p className="pt-5 text-justify">
            Embark on a journey with Kudos Track and experience the difference
            that genuine recognition can make in your organization. Together, we
            can create a world where every employee feels acknowledged, valued,
            and motivated to achieve their best.
          </p>
        </div>
      </section>
    </div>
  );
};

export default AboutUs;
