import useStore from "../../state/store";
import React from "react";
import { MoonLoader } from "react-spinners";

const ReviewLoader = () => {
  const isLoading = useStore((state) => state.isLoading);
  let color = isLoading ? "white" : "#007A77";
  return (
    <div
      className={`bg-white bg-opacity-75 z-40 h-[700px] w-[1024px] absolute flex justify-center items-center`}
    >  
      <MoonLoader color={color} size={20} speedMultiplier={0.5} />   
    </div>
  );
};

export default ReviewLoader;
