import React, { useEffect } from "react";

export const PrivacyPolicy = () => {
  useEffect(() => {
    document.title = "KudosTrack: Privacy Policy for Incident System";
    const metaTag = document.querySelector('meta[name="description"]');
    if (metaTag) {
      metaTag.setAttribute(
        "content",
        "Understand privacy at Kudos Track, a Special Incident Recording System. Learn how we handle and secure your data."
      );
    }
  }, []);

  return (
    <div className="">
      <section className="hero">
        <div className="hero-content pt-10">
          <h1 className="font-bold text-2xl">Kudos Track - Privacy policy</h1>
        </div>
      </section>

      <section className="company pt-10">
        <div className="company-content ">
          <h1 className="font-bold text-xl text">Introduction</h1>
          <p className="pt-5 text-justify">
            Kudos Track, the Special Incident Recording System, is a service
            provided by Sanmark Solutions Pvt Ltd. We are committed to
            protecting the privacy of all personal information collected from
            you while using our services, which include our software platform
            and any related mobile applications (collectively, the "Services").
            This Privacy Policy explains how we handle, store, and protect your
            information. Please read this document carefully before using our
            Services.
          </p>
          <p className="pt-5 text-justify">
            By using Kudos Track, you consent to the processing of your personal
            information as described in this Privacy Policy. Your data will be
            processed in the jurisdiction where our facilities are located and
            will be governed by applicable laws.
          </p>
        </div>
      </section>

      <section className="company pt-10">
        <div className="company-content ">
          <h1 className="font-bold text-xl text">Children’s Privacy</h1>
          <p className="pt-5 text-justify">
            Kudos Track is not designed for children under the age of 18. We do
            not intentionally collect any personal information from children
            under this age. If we become aware that a child under 18 has
            provided us with personal information, we will take steps to delete
            such information from our servers immediately.
          </p>
        </div>
      </section>

      <section className="company pt-10">
        <div className="company-content ">
          <h1 className="font-bold text-xl text">
            Information we receive from you and how we use it
          </h1>
          <b>User Registration:</b>
          <p className="pt-5 pb-5 text-justify">
            When you register for Kudos Track, you may voluntarily provide us
            with personal information, which may include your name, email
            address, job position, and details about your organization.
          </p>
          <b>User Content:</b>
          <p className="pt-5 pb-5 text-justify">
            You may upload information related to feedback or incidents within
            your organization. You warrant that you have the right to provide
            this information and that doing so does not violate any law or
            third-party rights.
          </p>
          <b>Technical and Usage Data:</b>
          <p className="pt-5 pb-5 text-justify">
            We collect information that your browser sends whenever you visit
            our Service or when you access the Service by or through a mobile
            device. This may include details like your IP address, browser type,
            browser version, our Service pages that you visit, the time and date
            of your visit, the time spent on those pages, unique device
            identifiers, and other diagnostic data.
          </p>
          <b>Cookies and Tracking:</b>
          <p className="pt-5 pb-5 text-justify">
            We use cookies and similar tracking technologies to track activity
            on our Service, and we hold certain information. Cookies are files
            with a small amount of data that may include an anonymous, unique
            identifier. You can instruct your browser to refuse all cookies or
            to indicate when a cookie is being sent.
          </p>
        </div>
      </section>

      <section className="company pt-5">
        <div className="company-content">
          <h1 className="font-bold text-xl">Use of Information</h1>
          <p className="pt-5">
            <b>Service Operation: </b>To maintain and enhance our Services,
            including troubleshooting, data analysis, and system testing.
          </p>
          <p className="pt-5">
            <b>Communication: </b>To communicate with you about any changes or
            improvements to our services.
          </p>
          <p className="pt-5">
            <b>User Support: </b>To provide customer support and respond to your
            inquiries.
          </p>
          <p className="pt-5">
            <b>Performance Monitoring: </b>To evaluate and improve the
            functionality of our Services, ensuring a tailored user experience.
          </p>
        </div>
      </section>

      <section className="company pt-10">
        <div className="company-content ">
          <h1 className="font-bold text-xl text">Disclosure of Information</h1>
          <p className="pt-5 text-justify">
            We may disclose your personal information under certain
            circumstances.
          </p>
          <p className="pt-5">
            <b>Legal Obligations: </b>When required by law, such as complying
            with a subpoena or similar legal process.
          </p>
          <p className="pt-5">
            <b>Protection of Rights: </b>To protect and defend the rights or
            property of Sanmark Solutions Pvt Ltd, including enforcing
            agreements and policies.
          </p>
          <p className="pt-5">
            <b>Safety: </b>To ensure the personal safety of users of our Service
            or the public at large.
          </p>
        </div>
      </section>

      <section className="company pt-10">
        <div className="company-content ">
          <h1 className="font-bold text-xl text">How we protect your data</h1>
          <p className="pt-5 text-justify">
            We take the security of your data seriously and implement robust
            measures to protect it from unauthorized access, alteration,
            disclosure, or destruction. However, no internet-based service can
            guarantee complete security, and we encourage you to take your own
            steps to protect your personal information.
          </p>
        </div>
      </section>

      <section className="company pt-10">
        <div className="company-content ">
          <h1 className="font-bold text-xl text">Your rights</h1>
          <p className="pt-5 text-justify">
            You have rights regarding the use of your personal data, including
            the right to access, correct, or request deletion of your personal
            information held by us. For instructions on exercising these rights,
            please contact us directly.
          </p>
        </div>
      </section>

      <section className="company pt-10">
        <div className="company-content ">
          <h1 className="font-bold text-xl text">
            Changes to this privacy policy
          </h1>
          <p className="pt-5 text-justify">
            We may update this Privacy Policy periodically to reflect changes in
            our practices or for other operational, legal, or regulatory
            reasons. Any changes will be effective immediately upon posting of
            the revised Privacy Policy.
          </p>
        </div>
      </section>

      <section className="company pt-10">
        <div className="company-content ">
          <h1 className="font-bold text-xl text">Contact Us</h1>
          <p className="pt-5 text-justify">
            If you have any questions about this Privacy Policy, this site's
            practices, or your dealings with this site, please email us at{" "}
            <a className="text-[#005655] underline" href="mailto:hello@SanmarkSolutions.com">
              hello@SanmarkSolutions.com
            </a>
            .
          </p>
        </div>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
