import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../components/ui/select";

import { Button } from "../../../components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../components/ui/form";

import { Check, ChevronsUpDown } from "lucide-react";

import { cn } from "../../../lib/utils";

import { Textarea } from "../../../components/ui/textarea";

import { format } from "date-fns";
import { Calendar as CalendarIcon } from "lucide-react";

import { Calendar } from "../../../components/ui/calendar";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "../../../components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../components/ui/popover";
import useStore from "../../../state/store";
import setAlert from "../../../components/alert/alert";
import { ChevronDown, ChevronUp } from "lucide-react"

const FormSchema = z.object({
  employee: z.string({
    required_error: "This field is required.",
  }),
  date: z.date({
    required_error: "This field is required.",
    invalid_type_error: "This field is required.",
  }),
  type: z.string({
    required_error: "This field is required.",
  }),
  quality: z.string({
    required_error: "This field is required.",
  }),
  review: z.string().min(10, {
    message: "Review must be at least 10 characters.",
  }),
});
const AddFeedback = () => {
  document.title = "KudosTrack - Add Review";
  React.useEffect(() => {
    if (user === null) {
      localStorage.removeItem("my-app-user");
      navigate("/");
    }
  }, []);
  const [open, setOpen] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const [close, setClose] = React.useState(false);

  const [revieweeId, setRevieweeId] = React.useState(null);
  const [qualityId, setQualityId] = React.useState(null);
  const [buttonDisabled, setButtonDisabled] = React.useState(false);

  const user = useStore((state) => state.user);
  const qualities = useStore((state) => state.qualities);
  const employees = useStore((state) => state.employees);
  const setIsLoading = useStore((state) => state.setIsLoading);
  const currentDate = new Date();
  const navigate = useNavigate();

  const form = useForm({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      date: currentDate,
      type: "Positive",
      review: "",
    },
  });

  const changeDateFormat = (dateToChange) => {
    const date = new Date(dateToChange);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    const mysqlDate = `${year}-${month}-${day}`;
    return mysqlDate;
  };
  function onSubmit(values) {
    let mySQLDate = changeDateFormat(values.date);
    if (
      user.userId != null &&
      revieweeId != null &&
      values.type != "" &&
      qualityId != null &&
      values.review != null &&
      mySQLDate != null
    ) {
      const FormData = require("form-data");
      let data = new FormData();
      data.append("reviewer_id", user.userId);
      data.append("reviewee_id", revieweeId);
      data.append("quality_type", values.type === "Positive" ? 1 : 0);
      data.append("quality_id", qualityId);
      data.append("content", values.review);
      data.append("date", mySQLDate);

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BACKEND_URL}/user/add-reviews`,
        headers: {
          Authorization: `Bearer ${user.access_token}`,
        },
        data: data,
      };
      delete config.headers["Content-Type"];
      setButtonDisabled(true);
      setIsLoading(true);
      axios
        .request(config)
        .then((response) => {
          setAlert("success", response.data.message);
          setTimeout(function () {
            form.reset();
            setIsLoading(null);
          }, 2000);
          setButtonDisabled(false);
        })
        .catch((error) => {
          setAlert("error", error.response.data.message);
          setIsLoading(null);
          setButtonDisabled(false);
        });
    }
  }

  const Select = ({ className, onValueChange, value, children }) => {
    const [isOpen, setIsOpen] = useState(false);

  const handleSelectClick = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (optionValue) => {
    onValueChange(optionValue);
    setIsOpen(false);
  };

  return (
    <div className={`relative inline-block w-full ${className}`}>
      <div
        className="block w-full bg-white border border-black hover:border-gray-500 px-4 py-2 pr-4 rounded shadow leading-tight focus:outline-none focus:shadow-outline cursor-pointer"
        onClick={handleSelectClick}
      >
        <div className="flex justify-between items-center">
          <span>{value}</span>
          <div className="pointer-events-none text-gray-700">
            {isOpen ? (
              <ChevronUp className="h-4 w-4" />
            ) : (
              <ChevronDown className="h-4 w-4" />
            )}
          </div>
        </div>
      </div>
      {isOpen && (
        <div className="absolute mt-1 w-full rounded-md bg-white shadow-lg z-10 border border-gray-200">
          <ul className="max-h-60 rounded-md py-1 text-base leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5">
            {React.Children.map(children, (child) =>
              React.cloneElement(child, {
                onClick: () => handleOptionClick(child.props.value),
                selected: child.props.value === value,
              })
            )}
          </ul>
        </div>
      )}
    </div>
  );
  };
  
  const SelectItem = ({ children, className, onClick, selected }) => {
    return (
      <li
        onClick={onClick}
        className={`cursor-pointer select-none relative py-2 pl-3 pr-9 ${className}`}
      >
        <Check className={`h-4 min-w-[24px] absolute left-2 top-3 ${selected ? 'opacity-100' : 'opacity-0'}`} />
        {children}
      </li>
    );
  };

  return (
    <div className="md:w-[700px] max-md:w-full mt-[66px] bg-white p-10 md:rounded-lg md:shadow md:h-auto ">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8 ">
          <FormField
            control={form.control}
            name="employee"
            render={({ field }) => (
              <FormItem className="flex flex-col">
                <div className="flex flex-col gap-2">
                  <FormLabel>Employee</FormLabel>
                  <Popover open={show} onOpenChange={setShow}>
                    <PopoverTrigger asChild>
                      <FormControl>
                        <Button
                          variant="outline"
                          role="combobox"
                          aria-expanded={show}
                          className="max-w-full justify-between relative border-black"
                        >
                          <span className="overflow-hidden mr-1">
                            {field.value
                              ? employees.find(
                                  (employee) => employee.name === field.value
                                )?.name
                              : "Select Employee"}
                          </span>

                          <ChevronsUpDown className="ml-2 h-4 w-4 nameshrink-2 opacity-50" />
                        </Button>
                      </FormControl>
                    </PopoverTrigger>
                    <PopoverContent className="search-dropdown sm:w-[620px] max-sm:w-[480px] max-[520px]:w-[380px] h-[250px] pb-2">
                      <Command>
                        <CommandInput placeholder="Search Employee..." />
                        <CommandEmpty>No Employee Found.</CommandEmpty>
                        <CommandGroup className="overflow-y-scroll">
                          {employees.map((employee) => (
                            <CommandItem
                              className="flex  flex-row gap-2"
                              key={employee.name}
                              value={employee.id}
                              onSelect={() => {
                                form.setValue("employee", employee.name);
                                setRevieweeId(employee.id);
                                setShow(false);
                              }}
                            >
                              <Check
                                className={cn(
                                  " h-4 min-w-[24px]",
                                  employee.name === field.value
                                    ? "opacity-100"
                                    : "opacity-0"
                                )}
                              />
                              <span className="overflow-hidden">
                                {employee.name}
                              </span>
                            </CommandItem>
                          ))}
                        </CommandGroup>
                      </Command>
                    </PopoverContent>
                  </Popover>
                </div>
                <FormMessage className="text-[12px]" />
              </FormItem>
            )}
          />
          <div className="flex flex-row w-full max-sm:flex-col gap-4 justify-between !mt-4">
            <div className="flex flex-col gap-2 w-full md:w-1/2">
              <FormField
                control={form.control}
                name="date"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Date</FormLabel>
                    <Popover open={close} onOpenChange={setClose}>
                      <PopoverTrigger asChild>
                        <FormControl>
                          <Button
                            variant={"outline"}
                            className={cn(
                              "w-[100%] justify-between text-left font-medium border-black",
                              !field.date && ""
                            )}
                          >
                            {field.value ? (
                              format(field.value, "PPP")
                            ) : (
                              <span>Pick a date</span>
                            )}
                            <CalendarIcon className="ml-2 h-4 w-4" />
                          </Button>
                        </FormControl>
                      </PopoverTrigger>
                      <PopoverContent className="w-auto p-0">
                        <Calendar
                          mode="single"
                          selected={field.value}
                          onSelect={(date) => {
                            field.onChange(date ? date : null);
                            setClose(false);
                          }}
                          disabled={(date) =>
                            date > new Date() || date < new Date("1900-01-01")
                          }
                          initialFocus
                        />
                      </PopoverContent>
                    </Popover>
                    <FormMessage className="text-[12px]" />
                  </FormItem>
                )}
              />
            </div>
            <div className="flex flex-col gap-2 w-full md:w-1/2">
              <FormField
                control={form.control}
                name="type"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Type</FormLabel>

                    <Select
                      className=""
                      onValueChange={field.onChange}
                      value={field.value}
                    >

                      <SelectItem
                        className="bg-green-400 hover:bg-green-500 rounded ml-1 mr-1"
                        value="Positive"
                        >
                        <div className="flex items-center pl-6">
                          <span className="text-base">Positive</span>
                        </div>
                      </SelectItem>
                      <SelectItem
                        className="bg-red-400 hover:bg-red-500 rounded mt-1 ml-1 mr-1"
                        value="Negative"
                        >
                        <div className="flex items-center pl-6">
                          <span className="text-base">Negative</span>
                        </div>
                      </SelectItem>
                    </Select>
                    <FormMessage className="text-[12px]" />
                  </FormItem>
                )}
              />
            </div>
          </div>
          <div className="flex flex-col  !mt-4 gap-2">
            <FormField
              control={form.control}
              name="quality"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Quality</FormLabel>
                  <FormControl>
                    <Popover open={open} onOpenChange={setOpen}>
                      <PopoverTrigger asChild>
                        <FormControl>
                          <Button
                            variant="outline"
                            role="combobox"
                            aria-expanded={open}
                            className=" justify-between border-black"
                          >
                            <span className="sm:w-[600px] overflow-hidden text-left">
                              {field.value
                                ? qualities.find(
                                    (quality) => quality.name === field.value
                                  )?.name
                                : "Select Quality"}{" "}
                            </span>
                            <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                          </Button>
                        </FormControl>
                      </PopoverTrigger>
                      <PopoverContent className="md:w-[620px] !max-md:w-[100%] h-[250px] pb-2">
                        <Command>
                          <CommandInput placeholder="Search Quality..." />
                          <CommandEmpty>No Quality Found.</CommandEmpty>
                          <CommandGroup className="overflow-y-scroll">
                            {qualities.map((quality) => (
                              <CommandItem
                                value={quality.name}
                                key={quality.id}
                                onSelect={() => {
                                  form.setValue("quality", quality.name);
                                  setQualityId(quality.id);
                                  setOpen(false);
                                }}
                              >
                                <Check
                                  className={cn(
                                    "mr-2 h-4 w-4",
                                    quality.name === field.value
                                      ? "opacity-100"
                                      : "opacity-0"
                                  )}
                                />
                                {quality.name}
                              </CommandItem>
                            ))}
                          </CommandGroup>
                        </Command>
                      </PopoverContent>
                    </Popover>
                  </FormControl>
                  <FormMessage className="text-[12px]" />
                </FormItem>
              )}
            />
          </div>
          <div className="pt-4">
            <FormField
              control={form.control}
              name="review"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Reviews</FormLabel>
                  <FormControl>
                    <Textarea
                      className="!mt-1 border-black w-/ max-sm:w-full"
                      placeholder="Type your reviews here."
                      {...field}
                    />
                  </FormControl>
                  <FormMessage className="text-[12px]" />
                </FormItem>
              )}
            />
          </div>
          <div className="float-right">
            <Button
              disabled={buttonDisabled}
              onClick={onSubmit}
              className="bg-[#007A77] hover:bg-[#005655] text-white"
              type="submit"
            >
              Submit
            </Button>
          </div>
        </form>
      </Form>
    </div>
  );
};
export default AddFeedback;
