import React from "react";
import { Icon } from "react-icons-kit";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { eye } from "react-icons-kit/feather/eye";

const ToggleEye = ({ type }) => {
  return (
    <span className="flex justify-center items-center absolute top-2 right-0 cursor-pointer">
      <Icon
        className="mr-2"
        icon={type === "password" ? eyeOff : eye}
        size={15}
      />
    </span>
  );
};

export default ToggleEye;
