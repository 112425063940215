import React from "react";
import { useLocation } from "react-router-dom";

const LandingPageFooter = ({ props }) => {
  const currentYear = new Date().getFullYear();
  const location = useLocation();
  const { pathname } = location;

  return (
    <div className="bottom-0 w-full flex max-md:flex-col justify-center max-md:items-center bg-transparent lg:gap-28 mb-6">
      
      <div className="max-md:flex-col justify-center">
        <div className="justify-center">
        <p className={`text-base ${pathname === "/" ? "text-white" : "text-black"} opacity-80 text-center max-sm:px-2`}>
            &copy; {currentYear}{" "}
            <a
              href="https://sanmarksolutions.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <strong>Sanmark Solutions PVT Ltd.</strong>
            </a>{" "}
            All rights reserved.
          </p>{" "}
        </div>
      </div>
    </div>
  );
};

export default LandingPageFooter;
