import React from "react";
import { useRoutes } from "react-router-dom";
import { Login } from "../../features/auth/signin/index";
import PublicLayout from "../../layout/public";
import SecondryPublicLayout from "../../layout/secondrypublic";
import ChangePassword from "../../features/auth/change-password";
import PrivateLayout from "../../layout/private";
import ForgotPassword from "../../features/auth/forgot-password";
import NewPassword from "../../features/auth/new-password";
import AddFeedback from "../../features/feedback/add-feedback";
import MyReview from "../../features/review/my-review";
import ContributorReview from "../../features/review/contributor-review";
import ContributorList from "../../features/list/contributor-list";
import ProtectedRoute from "../../router/protected-routes/ProtectedRoute";
import PageNotFound from "../../features/page-not-found";
import AboutUs from "../../features/static-page/about-us";
import PrivacyPolicy from "../../features/static-page/privacy-policy";
import UserGuide from "../../features/static-page/user-guide";
import AddRecoveryCodes from "../../features/auth/add-recovery-code";
import LandingLayout from "../../layout/landing";
import LandingPage from "../../features/landing-page";

const Routes = () => {
  let element = useRoutes([
    {
      path: "/",
      element: <LandingLayout />,
      children: [
        {
          path: "/",
          element: <LandingPage />,
        },
      ]
    },
    {
      path: "/",
      element: <PublicLayout />,
      children: [
        {
          path: "/sign-in",
          element: <Login />,
        },
        {
          path: "forgot-password",
          element: <ForgotPassword />,
        },
        ,
        {
          path: "add-recovery-code",
          element: <AddRecoveryCodes />,
        },
        {
          path: "new-password",
          element: <NewPassword />,
        },
      ],
    },
    {
      path: "/",
      element: <SecondryPublicLayout />,
      children: [
        {
          path: "about-us",
          element: <AboutUs />,
        },
        {
          path: "privacy-policy",
          element: <PrivacyPolicy />,
        },
        {
          path: "user-guide",
          element: <UserGuide />,
        },
      ]
    },
    {
      path: "private",
      element: (
        <ProtectedRoute>
          <PrivateLayout />
        </ProtectedRoute>
      ),
      children: [
        {
          path: "change-password",
          element: <ChangePassword />,
        },
        {
          path: "my-review",
          element: <MyReview />,
        },
        {
          path: "add-review",
          element: <AddFeedback />,
        },
        {
          path: "member-review/:contributorId",
          element: <ContributorReview />,
        },
        {
          path: "member-list",
          element: <ContributorList />,
        },
      ],
    },
    {
      path: "*",
      element: <PageNotFound />,
    },
  ]);

  return element;
};

export default Routes;
