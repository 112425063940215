import React, { useEffect, useState, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Outlet } from "react-router-dom";
import logo from "../resources/images/logo.webp";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Footer from "../components/footer/";

import { User } from "lucide-react";
import useStore from "../state/store";
import Loader from "../components/loader/index";
import authHandler from "../router/auth-handler/index";

const PrivateLayout = () => {
  const user = useStore((state) => state.user);
  const addUser = useStore((state) => state.addUser);
  const isLoading = useStore((state) => state.isLoading);
  const setIsLoading = useStore((state) => state.setIsLoading);
  const setEmployees = useStore((state) => state.setEmployees);
  const setQualities = useStore((state) => state.setQualities);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  const location = useLocation();
  const { pathname } = location;
  const dropdownRef = useRef(null);

  const getEmployeeList = (token) => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/user/get-all-users`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setEmployees(response.data.data);
        setIsLoading(null);
      })
      .catch((error) => {
        authHandler(error, navigate);
        setIsLoading(null);
      });
  };

  useEffect(() => {
    if (user != null) {
      getEmployeeList(user.access_token);
    }

    if (pathname === "/private") {
      logoutOrChangePassword();
    }
  }, [user]);

  const logoutOrChangePassword = () => {
    addUser(null);
    setQualities([]);
    setEmployees([]);
    setIsLoading(null);
    navigate("/");
  };

  const setNavigate = () => {
    navigate("/private/change-password");
  };
  let userName = user && user.name ? user.name : null;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShow(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div>
      <div className="w-full h-full font-arial">
        {isLoading === true && <Loader route={"private"} />}
        <div
          className={`flex flex-col justify-items-center  ${
            pathname === "/private/change-password"
              ? ""
              : "bg-gradient-to-r md:from-[#007A77] md:via-black md:to-[#007A77]"
          }`}
        >
          <div className="w-full shadow-md bg-white z-50 fixed">
            <nav className="w-[1024px] mx-auto max-lg:w-[700px] max-xl:w-[980px] max-md:w-[600px] max-sm:w-[90%] flex flex-row items-center justify-between py-4">
              <div className="w-[150px] h-auto cursor-pointer">
                <Link to="/private/add-review">
                  <img src={logo} alt="Logo" />
                </Link>
              </div>
              <div className="flex gap-8 max items-center">
                <div className="max-md:hidden">
                  <Link
                    to="/private/add-review"
                    className={`${
                      pathname === "/private/add-review"
                        ? "text-sky-500"
                        : "text-black"
                    } hover:text-sky-500 cursor-pointer`}
                  >
                    Add Review
                  </Link>
                </div>
                <div className="max-md:hidden">
                  <Link
                    to="/private/my-review"
                    className={`${
                      pathname === "/private/my-review"
                        ? "text-sky-500"
                        : "text-black"
                    } hover:text-sky-500 cursor-pointer`}
                  >
                    My Reviews
                  </Link>
                </div>
                <div className="max-md:hidden">
                  <Link
                    to="/private/member-list"
                    className={`${
                      pathname === "/private/member-list"
                        ? "text-sky-500"
                        : "text-black"
                    } hover:text-sky-500 cursor-pointer`}
                  >
                    Member Reviews
                  </Link>
                </div>
                <div ref={dropdownRef} className="relative inline-block text-left">
                  <div>
                    <button
                      type="button"
                      onClick={() => setShow(!show)}
                      className="flex max-w-28 min-w-24 items-center gap-2 text-black hover:text-sky-500 border py-1 px-2 rounded border-gray-500 hover:border-sky-500"
                      title={`${userName}`}
                    >
                      <User className="min-h-[20px] min-w-[20px] max-h-[20px] max-w-[20px]" />
                      <span className="overflow-hidden">Profile</span>
                    </button>
                  </div>

                  {show && (
                    <div className="absolute right-0 z-50 mt-2 w-48 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        <a
                          href="#"
                          onClick={() => {
                            setShow(false);
                            setNavigate();
                          }}
                          className="block px-4 py-2 text-black !hover:text-sky-500 cursor-pointer hover:bg-slate-100"
                        >
                          Change Password
                        </a>
                        <a
                          href="#"
                          onClick={() => {
                            logoutOrChangePassword();
                            setShow(false);
                          }}
                          className="block px-4 py-2 text-black !hover:text-sky-500 cursor-pointer hover:bg-slate-100"
                        >
                          Logout
                        </a>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </nav>
          </div>
          <div
            className={`w-full h-full min-h-[100vh] bg-center bg-cover flex md:items-center justify-center z-1 ${
              pathname === "/private/change-password"
                ? ""
                : "bg-gradient-to-r md:from-[#007A77] md:via-black md:to-[#007A77]"
            }`}
          >
            <Outlet />
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};
export default PrivateLayout;
