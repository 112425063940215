import * as React from "react";
import { ArrowUpDown } from "lucide-react";
import axios from "axios";
import useStore from "../../../state/store";
import { useNavigate , Link} from "react-router-dom";

import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Button } from "../../../components/ui/button";
import { Input } from "../../../components/ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/ui/table";
import authHandler from "../../../router/auth-handler/index";

const columns = [
  {
    accessorKey: "contributor",
    header: ({ column }) => (
      <div>
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Member
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      </div>
    ),
    cell: ({ row }) => (
      <div className="lowercase">{row.getValue("contributor")}</div>
    ),
  },
];
const ContributorList = () => {
  document.title = "KudosTrack - Member List";
  const [data, setData] = React.useState([]);
  const user = useStore((state) => state.user);
  const navigate = useNavigate();
  const setIsLoading = useStore((state) => state.setIsLoading);

  React.useEffect(() => {
    if (user != null) {
      fetchContributors();
    }
  }, []);

  const fetchContributors = () => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/user/get-contributors`, {
        headers: {
          Authorization: `Bearer ${user.access_token}`,
        },
      })
      .then((response) => {
        const updatedContributors = [];
        const contributors = response.data.data;
        for (let i = 0; i < contributors.length; i++) {
          updatedContributors.push({
            id: contributors[i].id,
            contributor: contributors[i].name,
          });
        }
        setData(updatedContributors);
        setIsLoading(null);
      })
      .catch((error) => {
        authHandler(error, navigate);
        setIsLoading(null);
      });
  };

  const [sorting, setSorting] = React.useState([]);
  const [columnFilters, setColumnFilters] = React.useState([]);
  const [columnVisibility, setColumnVisibility] = React.useState({});
  const [rowSelection, setRowSelection] = React.useState({});
  const table = useReactTable({
    data,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  });
  return (
    <div className="">
      <div className="mt-[66px] bg-white rounded-xl mx-auto w-[800px] h-[740px] p-[20px]">
        <div className="flex items-center py-4">
          <Input
            placeholder="Filter members..."
            value={table.getColumn("contributor")?.getFilterValue() || ""}
            onChange={(event) =>
              table.getColumn("contributor")?.setFilterValue(event.target.value)
            }
            className="max-w-sm border-gray-600"
          />
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableHead key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
        </div>
        <div className="rounded-md border">
          <Table>
            <TableBody>
              {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row) => (
                  <TableRow
                    key={row.id}
                    data-state={row.getIsSelected() && "selected"}
                  >
                    <div className="w-[700px]">
                      {row.getVisibleCells().map((cell) => (
                        <Link
                          to={
                            "/private/member-review/" +
                            data[cell.row.id].id
                          }
                        >
                          <div className="w-[758px] overflow-hidden">
                            <TableCell className=" max-w-[758px] pr-4  " key={cell.id}>
                            <div className="overflow-auto pr-2">
                            {data[cell.row.id].contributor}
                            </div>
                            </TableCell>
                          </div>
                        </Link>
                      ))}
                    </div>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={columns.length}
                    className="h-24 text-center"
                  >
                    No results.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <div className="flex items-center justify-end space-x-2 py-4">
          <div className="space-x-2">
            <Button
              variant="outline"
              size="sm"
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              Previous
            </Button>
            <Button
              variant="outline"
              size="sm"
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            >
              Next
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContributorList;
