import useStore from "../../state/store";
import { Button } from "../../components/ui/button";
import React from "react";
import { Link } from "react-router-dom";

const PageNotFound = () => {
  const user = useStore((state) => state.user);

  document.title = "KudosTrack - 404 Page";
  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <h1 className="font-sans-serif text-9xl font-bold text-red-400 mb-2">
        404
      </h1>
      <h1 className="font-mono text-4xl font-bold text-red-400 mb-2">
        Not Found
      </h1>
      <p className="text-lg text-gray-600">The page does not exist.</p>

      <Link to={user === null ? "/" : "/private/add-review"}>
        <Button className="w-40 p-2 text-white bg-[#007A77] hover:bg-[#005655] mt-4">
          Back to Home
        </Button>
      </Link>
    </div>
  );
};

export default PageNotFound;