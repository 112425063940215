import React from "react";
import { Outlet } from "react-router-dom";
import logo from "../resources/images/logo.webp";
import useStore from "../state/store";
import Loader from "../components/loader/index";
import NavBar from "../components/navbar";
import LandingPageFooter from "../components/footer/landing-page";

const LandingLayout = () => {
  const isLoading = useStore((state) => state.isLoading);
  return (
    <div className="bg-gradient-to-r from-[#007A77] via-black to-[#007A77] min-h-[100vh] justify-between flex flex-col">
      <NavBar />
      <div className="flex items-center justify-center">
        {isLoading === true && <Loader route={"public"} />}
        <div className="relative flex flex-wrap items-center justify-between gap-6 lg:gap-0 lg:py-4">
          <Outlet />
        </div>
      </div>
      <LandingPageFooter />
    </div>
  );
};

export default LandingLayout;
