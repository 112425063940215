import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { Button } from "../../../components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../components/ui/form";
import { Input } from "../../../components/ui/input";
import setAlert from "../../../components/alert/alert";
import { useEffect, useState } from "react";
import useStore from "../../../state/store";
import ToggleEye from "../../../components/toggle-eye";

const formSchema = z
  .object({
    new_password: z
      .string()
      .min(1, {
        message: "Password required.",
      })
      .min(8, {
        message: "Minimum 8 Charactors required.",
      })
      .regex(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#\-+])[A-Za-z\d@$!%*?&#\-+]+$/,
        {
          message: `* One uppercase letter.<br/>* One lowercase letter.<br/>* One number.<br/>* One special character.`,
        }
      ),
    confirm_password: z.string().min(1, {
      message: "Password required.",
    }),
  })
  .refine((data) => data.new_password === data.confirm_password, {
    message: "Passwords don't match.",
    path: ["confirm_password"],
  });

const NewPassword = () => {
  document.title = "KudosTrack - New Password";
  const location = useLocation();
  const navigate = useNavigate();
  const [typeNewpassword, setTypeNewpassword] = useState("password");
  const [typeConfirmpassword, setTypeConfirmpassword] = useState("password");
  const setIsLoading = useStore((state) => state.setIsLoading);

  useEffect(() => {
    console.log(location);
    if (
      location.state.email == null ||
      location.state.recoveryToken == null
    ) {
      navigate("/forgot-password");
    }
  }, []);

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      new_password: "",
      confirm_password: "",
    },
  });

  const onSubmit = (values) => {
    setIsLoading(true);
    const FormData = require("form-data");
    let data = new FormData();
    data.append("email", location.state.email);
    data.append("recoveryCode", location.state.recoveryToken);
    data.append("password", values.confirm_password);

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BACKEND_URL}/new-password`,
      data: data,
    };
    axios
      .request(config)
      .then((response) => {
        setAlert("success", response.data.message);
        navigate("/");
        setIsLoading(null);
      })
      .catch((error) => {
        setAlert("error", error.response.data.message);
        setIsLoading(null);
      });
  };
  const handleToggle = (passwordType) => {
    if (passwordType === "new") {
      typeNewpassword === "password"
        ? setTypeNewpassword("text")
        : setTypeNewpassword("password");
    } else if (passwordType === "confirm") {
      typeConfirmpassword === "password"
        ? setTypeConfirmpassword("text")
        : setTypeConfirmpassword("password");
    }
  };
  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="space-y-4 w-[320px] max-[480px]:w-[unset]"
      >
        <FormField
          control={form.control}
          name="new_password"
          render={({ field }) => (
            <FormItem>
              <FormLabel>New password</FormLabel>
              <FormControl>
                <div className="border rounded-md relative flex gap-2">
                  <Input
                    className="!border-0 !focus:outline-none"
                    name="new_password"
                    type={typeNewpassword}
                    placeholder="New password"
                    {...field}
                  />
                  <div className="flex item-center w-[15px] h-[15px]" onClick={() => handleToggle("new")}>
                    <ToggleEye type={typeNewpassword} />
                  </div>
                </div>
              </FormControl>
              <FormMessage className="text-[12px]" />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="confirm_password"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Confirm password</FormLabel>
              <FormControl>
                <div className="border rounded-md relative flex gap-2">
                  <Input
                    className="!border-0 !focus:outline-none"
                    name="confirm_password"
                    type={typeConfirmpassword}
                    placeholder="Confirm password"
                    {...field}
                  />
                  <div className="flex item-center w-[15px] h-[15px]" onClick={() => handleToggle("confirm")}>
                    <ToggleEye type={typeConfirmpassword} />
                  </div>
                </div>
              </FormControl>
              <FormMessage className="text-[12px]" />
            </FormItem>
          )}
        />
        <Button
          className="w-full bg-[#007A77] hover:bg-[#005655]"
          type="submit"
        >
          Submit
        </Button>
      </form>
    </Form>
  );
};

export default NewPassword;
